<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Lead Source'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/leadsource`"
                title="Lead Source"
                :columns="columns"
                routerpath="/master/crm"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import TabPosition from '../../../../components/TabView.vue'
import GoodTableColumnSearch from '../../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition
  },
  data () {
    return {
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields:[
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'dropdown',
          responseValue:'name',
          class: "col-md-4",
        },
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Lead Source' : 'Add Lead Source'
        }`,
        submitRouterPath: '/master/crm/leadsource',
        gridForm: true,
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getleadsourceById`,
        url: `${baseApi}/leadsource`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Name',
            required: true
          },
          {
            label: 'Parent',
            name: 'parent',
            value: '',
            type: 'dropdown',
            url: 'leadsourcedata',
            responseValue: 'id',
            class: 'col-md-12',
            dropdownname: 'parent',
            required: false,
            options: []
          }
        ]
      },
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'dropdown',
          url: 'leadsourcedata',
          responseValue: 'name',
          dropdownname: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Parent'
          }
        },
         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
         {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      edit: '',
      add: '',
      canDelete: ''
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Lead Source') {
          this.edit = item.edit
          this.add = item.add
          this.canDelete = item.delete
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  },
  beforeMount () {
    this.getDropdowns()
  },
  methods: {
    async getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })

      // this.assignToFormOptions.inputFields.map(async (item) => {
      //   if (item.type == 'dropdown' && item.url) {
      //     const requestOptionsUsers = {
      //       method: 'GET',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         'Authorization': `Bearer ${accessToken}`
      //       },
      //       url: `${baseApi}/${item.url}`
      //     }

      //     await axios(requestOptionsUsers)
      //       .then((response) => {
      //         response.data.data.data
      //           ? (item.options = response.data.data.data)
      //           : (item.options = response.data.data)

      //         // this.form[item.name] = data[item.name];
      //         // if (item.name == item.dropdownname) {
      //         //   item.options.map((user) => {
      //         //     if (user.id == this.form[item.dropdownname]) {
      //         //       item.item = user.name;
      //         //     }
      //         //   });
      //         // }
      //       })
      //       .catch((error) => console.log(error, 'error'))
      //   }
      // })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
